import React from 'react'
import { blogger, english, relationship, entertainment, work } from '../utils/categoryCards'
import { Layout, HorizontalList, SEO } from '../components'

import './categories.scss'

const Categories = () => {
  return (
    <Layout className="categories-page" centerContentVertically>
      <SEO subtitle="Категории вопросов" />
      <div className="section-inner vertical-indent--m">
        <div className="categories-page__category-wrapper">
          <h2>Вопросы в стиле популярных ведущих и блогеров</h2>
          <HorizontalList>
            {blogger.dud}
            {blogger.aPogovorit}
            {blogger.makarena}
            {blogger.pozner}
            {blogger.russkieNorm}
            {blogger.guriev}
          </HorizontalList>
        </div>
        <div className="categories-page__category-wrapper">
          <h2>Вопросы про отношения</h2>
          <HorizontalList>
            {relationship.firstDate}
            {relationship.aboutPartner}
            {relationship.aboutLove}
            {relationship.aboutSex}
            {relationship.aboutMarriage}
          </HorizontalList>
        </div>
        <div className="categories-page__category-wrapper">
          <h2>Вопросы про хобби и развлечения</h2>
          <HorizontalList>
            {entertainment.hobbies}
            {entertainment.music}
            {entertainment.sport}
            {entertainment.movies}
            {entertainment.books}
          </HorizontalList>
        </div>
        <div className="categories-page__category-wrapper">
          <h2>Вопросы про работу и карьеру</h2>
          <HorizontalList>
            {work.interview}
            {work.work}
            {work.career}
          </HorizontalList>
        </div>
        <div className="categories-page__category-wrapper">
          <h2>Вопросы на английском языке</h2>
          <HorizontalList>{english.sparkFunEn}</HorizontalList>
        </div>
      </div>
    </Layout>
  )
}

export default Categories
